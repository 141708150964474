export class AuthService {
  loggedIn=false;

  isAuthenticated() {
    const promise = new Promise((resolve,reject)=> {
      setTimeout(()=> {
        resolve(this.loggedIn)
      },800)
    });
    return promise;
  }

  login(password:string) {
    password==="Goofballs" ? this.loggedIn=true : this.loggedIn=false;
  }


}