<div class="hero">
  <h1>Swanson Funhouse</h1>
  <button
    type="button"
    class="block"
    (click)="playGoofy()"
  >
    Matchy Matchy - Goofballs Edition
  </button>
  <button
    type="button"
    class="block"
    (click)="playWedding()"
  >
    Matchy Matchy - Wedding Edition
  </button>
  <button type="button" class="block" [routerLink]="['../videos']">
    Videos
  </button>
</div>
