<div class="hero">
  <button type="button" class="menu-button" (click)="gotoMenu()">Menu</button>
  <h1>Videos</h1>
  <a
    href="https://www.tiktok.com/@maxscomedyhour/video/7094622732758895914?_t=8S7PkSFOdFy&_r=1"
    target="_blank"
  >
    <button type="button" class="block">Jacob The Teacher</button>
  </a>
  <a href="https://www.youtube.com/watch?v=S_7xOs2NfoI" target="_blank">
    <button type="button" class="block">Stephen Lindy Hops</button>
  </a>
    <a href="assets/videos/AdamAndHannahOnWedding.mp4" target="_blank">
    <button type="button" class="block">Adam & Hannah on their Role In Rob & E's Wedding</button>
  </a>
</div>
