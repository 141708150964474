<h2 mat-dialog-title>Matchy Matchy {{data.gameType}} Edition</h2>
<mat-dialog-content class="mat-typography">
  <p>Play against yourself or your alter ego.  Make a match, your turn again.</p>
  <p>Player 1:&nbsp;{{data.player1Name}}</p>
  <p>Player 2:&nbsp;{{data.player2Name}}</p>
<mat-form-field>
  <mat-label>Number of Pairs</mat-label>
  <select #selectPairs matNativeControl [(value)]="data.numberPairs">
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    <option value="10">10</option>
    <option value="11">11</option>
    <option value="12">12</option>
  </select>
</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onClose()">Start</button>
</mat-dialog-actions>
