import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CardData } from '../card-data.model';
import { RestartDialogComponent } from '../matchy/restart-dialog/restart-dialog.component';
import { StartDialogComponent } from './start-dialog/start-dialog.component';

@Component({
  selector: 'matchy',
  templateUrl: './matchy.component.html',
  styleUrls: ['./matchy.component.scss'],
})
export class MatchyComponent implements OnInit {
  title = 'Swanson Fun House';
  @ViewChild('player1') player1: ElementRef;
  @ViewChild('player2') player2: ElementRef;

  numImagesGoofballs = 87;
  numImagesWedding = 49;
  gameType = 'goofballs';
  cardImages = [];
  matched = false;
  duos = [
    ['Dr. Jekyll', 'Mr. Hyde'],
    ['Butch Cassidy', 'Sundance Kid'],
    ['Bozo', 'Ronald'],
    ['Krusty', 'Pennywise'],
    ['Obi-Wan', 'Darth'],
    ['Hector', 'Achilles'],
    ['Ricky Bobby', 'Jean Girard'],
    ['The Greasers', 'The Socs'],
  ];
  weddingDuos = [
    ['Bonnie', 'Clyde'],
    ['Beyoncé', 'Jay-Z'],
    ['Johnny', 'June Carter'],
    ['Ellen', 'Portia'],
    ['John', 'Yoko'],
    ['John', 'Abigail'],
    ['Abraham', 'Mary'],
    ['Diego', 'Frida'],
    ['Martin Luther', 'Coretta Scott'],
    ['Ulysses', 'Julia'],
    ['Luke','Laura'],
    ['Antony','Cleopatra']
  ];
  player1Name = 'Dr. Jekyll';
  player2Name = 'Mr. Hyde';
  numberPairs = 6;
  player1Score = 0;
  player2Score = 0;

  cards: CardData[] = [];

  flippedCards: CardData[] = [];

  matchedCount = 0;

  shuffleArray(anArray: any[]): any[] {
    return anArray
      .map((a) => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map((a) => a[1]);
  }

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.style == 'wedding') {
        this.gameType = 'wedding';
      } else {
        this.gameType = 'goofballs';
      }
       this.setupPlayNames();
    });
    const randomNum = Math.floor(Math.random() * 4);
    const dialogRef = this.dialog.open(StartDialogComponent, {
      autoFocus: true,
      disableClose: true,
      data: {
        gameType: this.gameType[0].toUpperCase() + this.gameType.substring(1),
        player1Name: this.player1Name,
        player2Name: this.player2Name,
        numberPairs: this.numberPairs,
      },
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.numberPairs = result;
      console.log(result);
      this.setupCards();
    });
  }

  setupCards(): void {
    this.cardImages = [];

    if (this.gameType === 'goofballs') {
      do {
        let addRandom = Math.floor(
          Math.random() * (this.numImagesGoofballs - 1)
        );
        this.cardImages.push('img' + addRandom + '.jpg');
      } while (this.cardImages.length < this.numberPairs);
    } else {
      do {
        let addRandom =
          Math.floor(Math.random() * (this.numImagesWedding - 1)) + 1000;
        this.cardImages.push('img' + addRandom + '.jpg');
      } while (this.cardImages.length < this.numberPairs);
    }

    this.cards = [];
    this.cardImages.forEach((image) => {
      const cardData: CardData = {
        imageUrl: image,
        state: 'default',
      };

      this.cards.push({ ...cardData });
      this.cards.push({ ...cardData });
    });

    this.cards = this.shuffleArray(this.cards);
  }

  cardClicked(index: number): void {
    const cardInfo = this.cards[index];

    if (cardInfo.state === 'default' && this.flippedCards.length < 2) {
      const audio = new Audio('assets/sounds/Card-flip-sound-effect.mp3');
      audio.play();
      cardInfo.state = 'flipped';
      this.flippedCards.push(cardInfo);

      if (this.flippedCards.length > 1) {
        this.checkForCardMatch();
      }
    } else if (cardInfo.state === 'flipped') {
      cardInfo.state = 'default';
      this.flippedCards.pop();
    }
  }

  checkForCardMatch() {
    setTimeout(() => {
      const cardOne = this.flippedCards[0];
      const cardTwo = this.flippedCards[1];
      const nextState =
        cardOne.imageUrl === cardTwo.imageUrl ? 'matched' : 'default';
      cardOne.state = cardTwo.state = nextState;

      this.flippedCards = [];

      if (nextState === 'matched') {
        this.matchedCount++;
        this.matched = true;
        const audio = new Audio(
          'assets/sounds/jump-video-game-06-sound-effect.mp3'
        );
        audio.play();
        this.player1.nativeElement.checked
          ? this.player1Score++
          : this.player2Score++;
        if (this.matchedCount === this.cardImages.length) {
          let winningPlayer="";
          if (this.player1Score>this.player2Score)
            winningPlayer=`${this.player1Name} Wins!`;
          else if (this.player2Score>this.player1Score)
            winningPlayer=`${this.player2Name} Wins!`;
          else 
            winningPlayer='Tie Game';

          const dialogRef = this.dialog.open(RestartDialogComponent, {
            disableClose: true,
            data: { winner: winningPlayer },
            panelClass: 'custom-dialog-container',
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result === 'done')
              this.router.navigate(['../menu'], { relativeTo: this.route });
            if (result === 'goofballs') {
              this.gameType = 'goofballs';
            } else {
              this.gameType = 'wedding';
            }
            this.restart();
          });
        }
      } else {
        this.player1.nativeElement.checked =
          !this.player1.nativeElement.checked;
        this.player2.nativeElement.checked =
          !this.player2.nativeElement.checked;
      }
    }, 1500);
  }

  setupPlayNames():void {
     if (this.gameType == 'wedding') {
       const randomNum = Math.floor(
         Math.random() * (this.weddingDuos.length - 1)
       );
       this.player1Name = this.weddingDuos[randomNum][0];
       this.player2Name = this.weddingDuos[randomNum][1];
     } else {
       const randomNum = Math.floor(Math.random() * (this.duos.length - 1));
       this.player1Name = this.duos[randomNum][0];
       this.player2Name = this.duos[randomNum][1];
     }
  }

  restart(): void {
    this.setupPlayNames();
    this.matchedCount = 0;
    this.matched = false;
    this.player1Score = 0;
    this.player2Score = 0;
    this.setupCards();
  }
}
