<h2 mat-dialog-title>{{ data.winner }}</h2>
<mat-dialog-content class="mat-typography">
  (●'◡'●)(❁´◡`❁)(●'◡'●)
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()">Done</button>
  <button
    mat-raised-button
    color="primary"
    (click)="onGoofballsClick()"
    cdkFocusInitial
  >
    Goofballs
  </button>
  <button mat-raised-button color="accent" (click)="onWeddingClick()">
    Wedding
  </button>
</mat-dialog-actions>
