import { Component, OnInit, Inject, Injectable, HostListener } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AuthService } from '../auth-service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css'],
})
@Injectable()
export class LoginDialogComponent implements OnInit {
  password: string;
  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<LoginDialogComponent>
  ) {}

  @HostListener('window:keyup.Enter', ['$event'])
  onDialogClick(event: KeyboardEvent): void {
    this.login();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  login(): void {
    this.authService.login(this.password);
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
