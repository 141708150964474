<h1 mat-dialog-title>Login</h1>
<div mat-dialog-content>
  <input
    type="password"
    placeholder="password"
    id="password"
    [(ngModel)]="password"
    autofocus
  />
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button mat-primary (click)="login()" cdkFocusInitial>
    Ok
  </button>
</div>
