<div class="all-background">
  <div class="x">
    <img
      class="y"
      src="assets/img/Princesses.jpg"
      alt="Princess Syndrome - scariest of all"
      title="Princess Syndrome - scariest of all"
    />
  </div>
  <div class="x2">
    <img
      class="y2"
      src="assets/img/JeffCropped.jpg"
      alt="Gangster - Beware"
      title="Gangster - Beware"
    />
  </div>
  <div class="x3">
    <img class="y3" src="assets/img/TylerBunny.jpg" title="Innocent?  Not!" />
  </div>
  <button id="enter-button" (click)="enterClick()" class="btn btn-success">
    Enter
  </button>
</div>
