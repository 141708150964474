import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-restart-dialog',
  templateUrl: './restart-dialog.component.html',
  styleUrls: ['./restart-dialog.component.css'],
})
export class RestartDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { winner: number },
    public dialogRef: MatDialogRef<RestartDialogComponent>
  ) {}

  ngOnInit(): void {
    const audio = new Audio('assets/sounds/he-yah-5-sound-effect.mp3');
    audio.play();
  }

  onNoClick(): void {
    this.dialogRef.close('done');
  }

  onWeddingClick(): void {
    this.dialogRef.close('wedding');
  }

  onGoofballsClick(): void {
    this.dialogRef.close('goofballs');
  }
}
