import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-start-dialog',
  templateUrl: './start-dialog.component.html',
  styleUrls: ['./start-dialog.component.css'],
})
export class StartDialogComponent implements OnInit {
  @ViewChild('selectPairs') selectPairs: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      gameType: string;
      player1Name: string;
      player2Name: string;
      numberPairs: number;
    },
    public dialogRef: MatDialogRef<StartDialogComponent>
  ) {}

  ngOnInit(): void {
    /*
    let audio = new Audio();
    if (this.data.gameType === 'Wedding')
      audio.src = 'assets/sounds/wedding-march.mp3';
    else audio.src = 'assets/sounds/goofy-laugh.mp3';
    audio.play(); */
  }
  onClose() {
    const pairs = this.selectPairs.nativeElement.value;
    this.dialogRef.close(pairs);
  }
}
