import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  title = 'Swanson Fun House';
  soundEffect = new Audio();

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.soundEffect.autoplay = true;
  }

  playWedding() {
    this.initializeSoundEffect();
    setTimeout(() => {
      this.soundEffect.src = 'assets/sounds/wedding-march.mp3';
      this.router.navigate(['../matchy'], {
        relativeTo: this.route,
        queryParams: { style: 'wedding' },
      });
    }, 1000);
  }

  playGoofy() {
    this.initializeSoundEffect();
    setTimeout(() => {
      this.soundEffect.src = 'assets/sounds/goofy-laugh.mp3';
      this.router.navigate(['../matchy'], {
        relativeTo: this.route,
        queryParams: { style: 'goofballs' },
      });
    }, 1000);
  }

  initializeSoundEffect() {
    this.soundEffect.src =
      'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  }
}
