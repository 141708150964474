import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GameCardComponent } from './matchy/game-card/game-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RestartDialogComponent } from './matchy/restart-dialog/restart-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatchyComponent } from './matchy/matchy.component';
import { SplashComponent } from './splash/splash.component';
import { MenuComponent } from './menu/menu.component';
import { VideoComponent } from './video/video.component';
import { AuthService } from './auth-service';
import { AuthGuard } from './auth-guard-service';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { StartDialogComponent } from './matchy/start-dialog/start-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    GameCardComponent,
    RestartDialogComponent,
    MatchyComponent,
    SplashComponent,
    MenuComponent,
    VideoComponent,
    LoginDialogComponent,
    StartDialogComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [AuthService, AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
