import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth-guard-service';
import { MatchyComponent } from './matchy/matchy.component';
import { MenuComponent } from './menu/menu.component';
import { SplashComponent } from './splash/splash.component';
import { VideoComponent } from './video/video.component';

const appRoutes: Routes = [
  { path: '', component: SplashComponent },
  { path: 'matchy', component: MatchyComponent, canActivate: [AuthGuard] },
  { path: 'menu', component: MenuComponent, canActivate: [AuthGuard] },
  { path: 'videos', component: VideoComponent, canActivate: [AuthGuard] },
  { path: '**', component: SplashComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes,{useHash:true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
