<div 
  class="p-16"
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  "
>
  <div style="width: auto; margin: auto">
    <a  [routerLink]="['../menu']" ><span style="font-family: freakshow; font-size: 24px; padding-right: 10px"
      >Menu</span
    ></a>
    <input type="radio" name="player1" #player1 value="1" checked />
    <label for="player1">{{player1Name}}</label>&nbsp;
    <span id="score1">Score: {{ player1Score }}</span
    >&nbsp;&nbsp;
    <input type="radio" name="player2" #player2 value="2" />
    <label for="player2">{{player2Name}}</label>&nbsp;
    <span id="score1">Score: {{ player2Score }}</span>
  </div>
  <div class="grid p-16">
    <app-game-card
      *ngFor="let c of cards; let idx = index"
      [data]="c"
      (cardClicked)="cardClicked(idx)"
    >
    </app-game-card>
  </div>
</div>
